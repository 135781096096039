import React from 'react';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import history from 'redux/history';
import store from 'redux/store';
import { PATHS } from 'res/routes';

// layout
import AuthLayout from 'layouts/AuthLayout';
import ContentLayout from 'layouts/ContentLayout';

// pages
import Image from 'pages/image/imagePage';
import Trash from 'pages/image/trashPage';

import CameraAddPage from 'pages/camera/cameraAddPage';
import CameraAddPin from 'pages/camera/cameraAddPin';
import CameraEdit from 'pages/camera/cameraEdit';
import CameraEditPin from 'pages/camera/cameraEditPin';
import CameraPage from 'pages/camera/cameraPage';
import CameraRemotePage from 'pages/camera/cameraRemotePage';

import ActivityPage from 'pages/activity/activityPage';
import Dashboard from 'pages/activity/dashboardPage';
import AuthEnterPassword from 'pages/auth/authEnterPassword';
import AuthResetEmailSent from 'pages/auth/authResetEmailSent';
import AuthResetPassword from 'pages/auth/authResetPassword';
import AuthSignInPage from 'pages/auth/authSiginPage';
import ImageUpload from 'pages/image/imageUpload';
import ProfilePage from 'pages/profile/profilePage';

import PrivateRoute from 'layouts/PrivateRoute';
import PredictionPage from 'pages/activity/PredictionPage';
import BestTimePage from 'pages/activity/bestTimePage';
import DataUsagePage from 'pages/activity/dataUsagePage';
import MovementMapPage from 'pages/activity/movementMapPage';
import WeatherPage from 'pages/activity/weatherPage';
import AuthDeveloper from 'pages/auth/authDeveloper';
import AuthSignInTokenPage from 'pages/auth/authSiginTokenPage';
import CameraGroup from 'pages/camera/cameraGroupPage';
import ImageTrashMorePage from 'pages/image/ImageTrashMorePage';
import ImageTagPage from 'pages/image/imageTagPage';
import DetectionThreshold from 'pages/profile/detectionThreshold';
import ManageSharePage from 'pages/share/manageShare';
import SharedCameraPage from 'pages/share/sharedCamera';
import SharedImagePage from 'pages/share/sharedImagePage';
import SubActivityPage from 'pages/sub/SubActivityPage';
import SubCameraPage from 'pages/sub/SubCameraPage';
import SubImagePage from 'pages/sub/SubImagePage';
import SmartFeeder from 'pages/camera/SmartFeeder';

import 'App.less';

function App() {
  const renderWithLayout = (Component, Layout) => <Layout>{Component}</Layout>;
  return (
    <div className="App">
      <Provider store={store}>
        <div className="App">
          <Router history={history}>
            <div>
              <Switch>
                <PrivateRoute
                  path={PATHS.HOME}
                  exact
                  render={() => renderWithLayout(<Dashboard />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.IMAGE}
                  exact
                  render={() => renderWithLayout(<Image />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.IMAGE_TRASH_MORE}
                  exact
                  render={() => renderWithLayout(<ImageTrashMorePage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.IMAGE_TAG}
                  exact
                  render={() => renderWithLayout(<ImageTagPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.TRASH}
                  exact
                  render={() => renderWithLayout(<Trash />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.CAMERA}
                  exact
                  render={() => renderWithLayout(<CameraPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.CAMERA_GROUP}
                  exact
                  render={() => renderWithLayout(<CameraGroup />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.CAMERA_ADD}
                  exact
                  render={() => renderWithLayout(<CameraAddPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.CAMERA_EDIT}
                  exact
                  render={() => renderWithLayout(<CameraEdit />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.CAMERA_REMOTE}
                  exact
                  render={() => renderWithLayout(<CameraRemotePage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.SMART_FEEDER}
                  exact
                  render={() => renderWithLayout(<SmartFeeder />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.CAMERA_EDIT_PIN}
                  exact
                  render={() => renderWithLayout(<CameraEditPin />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.CAMERA_ADD_PIN}
                  exact
                  render={() => renderWithLayout(<CameraAddPin />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.SUB_CAMERA}
                  exact
                  render={() => renderWithLayout(<SubCameraPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.SUB_IMAGE}
                  exact
                  render={() => renderWithLayout(<SubImagePage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.SUB_ACTIVITY}
                  exact
                  render={() => renderWithLayout(<SubActivityPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.ACTIVITY}
                  exact
                  render={() => renderWithLayout(<ActivityPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.BESTTIME}
                  exact
                  render={() => renderWithLayout(<BestTimePage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.PREDICTION}
                  exact
                  render={() => renderWithLayout(<PredictionPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.WEATHER}
                  exact
                  render={() => renderWithLayout(<WeatherPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.PROFILE}
                  exact
                  render={() => renderWithLayout(<ProfilePage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.DETECTION_THRESHOLD}
                  exact
                  render={() => renderWithLayout(<DetectionThreshold />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.IMAGE_UPLOAD}
                  exact
                  render={() => renderWithLayout(<ImageUpload />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.DATA_USAGE}
                  exact
                  render={() => renderWithLayout(<DataUsagePage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.MOVEMENT_MAP}
                  exact
                  render={() => renderWithLayout(<MovementMapPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.MANAGE_SHARE}
                  exact
                  render={() => renderWithLayout(<ManageSharePage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.SHARED_CAMERA}
                  exact
                  render={() => renderWithLayout(<SharedCameraPage />, ContentLayout)}
                />
                <PrivateRoute
                  path={PATHS.SHARE_IMAGE}
                  exact
                  render={() => renderWithLayout(<SharedImagePage />, ContentLayout)}
                />
                <Route
                  path={PATHS.SIGNIN}
                  exact
                  render={() => renderWithLayout(<AuthSignInPage />, AuthLayout)}
                />
                <Route
                  path={PATHS.SIGNIN_WITH_TOKEN}
                  exact
                  render={() => renderWithLayout(<AuthSignInTokenPage />, AuthLayout)}
                />
                <Route
                  path={PATHS.AUTH_DEVELOPER}
                  exact
                  render={() => renderWithLayout(<AuthDeveloper />, AuthLayout)}
                />
                <Route
                  path={PATHS.AUTH_RESET_PASSWORD}
                  exact
                  render={() => renderWithLayout(<AuthResetPassword />, AuthLayout)}
                />
                <Route
                  path={PATHS.AUTH_ENTER_PASSWORD}
                  exact
                  render={() => renderWithLayout(<AuthEnterPassword />, AuthLayout)}
                />
                <Route
                  path={PATHS.AUTH_RESET_EMAIL_SENT}
                  exact
                  render={() => renderWithLayout(<AuthResetEmailSent />, AuthLayout)}
                />
              </Switch>
            </div>
          </Router>
        </div>
      </Provider>
    </div>
  );
}

export default App;
