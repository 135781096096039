import { Button, Card, Checkbox, Col, Form, Grid, Input, InputNumber, InputNumberProps, notification, Radio, Row, Select, Slider, Space, Spin, Tag } from 'antd';
import * as Api from 'api';
import { CameraPinType } from 'helpers/types';
import React, { useEffect, useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
// import { CATEGORIES } from 'res/constants';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const SmartFeeder = () => {
  const { md } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [feeders, setFeeders] = useState<CameraPinType[]>([]);
  const [feeder, setFeeder] = useState<any>(null);
  const [openCategories, setOpenCategories] = React.useState<string[]>(['Other']);
  const [closeCategories, setCloseCategories] = React.useState<string[]>([]);
  const [closeInRain, setCloseInRain] = React.useState<boolean>(false);
  const [openInDry, setOpenInDry] = React.useState<boolean>(false);
  const [selectedCameras, setSelectedCameras] = useState<any[]>([]);
  const [actionStay, setActionStay] = useState(5);
  const [cameras, setCameras] = useState<CameraPinType[]>([]);
  const [cameraGroups, setCameraGroups] = useState<any[]>([]);
  const [feederState, setFeederState] = useState<any>({
    door_position: '',
    rain_sensor: '',
    feed_level: '',
    csq: '',
    dbm: '',
  });
  const [openSunrise, setOpenSunrise] = useState<string>();
  const [closeSunset, setCloseSunset] = useState<string>();
  const [thresholdValue, setThresholdValue] = useState(1);
  const [nonThresholdValue, setNonThresholdValue] = useState(1);
  const [defaultMode, setDefaultMode] = useState<string>('open');
  const [categories, setCategories] = useState([]);

  const { auth: { profile } } = useAuth();
  const [form] = Form.useForm();

  useEffect(() => {
    loadDFeeders();
    loadCamerasGroups();
    http_load_categories();
  }, []);

  const http_load_categories = () => {
    Api.DETECTION_THRESHOLD().then(((res: any) => {
      const data = JSON.parse(res.text);
      const tcategories = data.filter((item) => item.enable == '1');
      const cates = tcategories.map((item) => item.category);
      cates.push('Other');
      setCategories(cates);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const loadCamerasGroups = () => {
    Api.CAMERAS_GROUPS().then(((res: any) => {
      const result = JSON.parse(res.text);
      setCameras(result.cameras);
      setCameraGroups(result.cameraGroups);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const loadDFeeders = () => {
    setLoading(true);
    Api.SMARTFEEDER_ALL().then(((res: any) => {
      setLoading(false);
      const result = JSON.parse(res.text);
      setFeeders(result);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  useEffect(() => {
    if (feeders.length > 0) {
      setFeeder(feeders[0].IMEI);
    }
  }, [feeders]);

  useEffect(() => {
    loadFeederSetting();
    loadFeederStatus();
  }, [feeder]);

  const loadFeederStatus = () => {
    Api.SMARTFEEDER_STATE(feeder).then(((res: any) => {
      setLoading(false);
      const result = JSON.parse(res.text);
      setFeederState(result);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const loadFeederSetting = () => {
    setLoading(true);
    Api.SMARTFEEDER_SETTING_GET(feeder).then(((res: any) => {
      setLoading(false);
      const result = JSON.parse(res.text);
      setDefaultMode(result.default_mode);
      setCloseInRain(result.rain_close == '1');
      setOpenInDry(result.dry_open == '1');
      setOpenSunrise(result.open_sunrise);
      setCloseSunset(result.close_sunset);
      setThresholdValue(parseInt(result.target_threshold, 10));
      setNonThresholdValue(parseInt(result.nontarget_threshold, 10));
      setActionStay(result.action_stay);
      setSelectedCameras(result.cameras);
      setOpenCategories(result.open_categories);
      setCloseCategories(result.close_categories);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onAllStop = () => {
    const params = {
      IMEI: feeder,
      command: 'all_stop',
    };

    Api.SMARTFEEDER_COMMAND(params).then((res: any) => {
      console.log('command response', res);
      notification.open({
        message: 'The command has been sent.',
      });
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onOpen = () => {
    const params = {
      IMEI: feeder,
      command: 'force_open',
    };

    Api.SMARTFEEDER_COMMAND(params).then((res: any) => {
      console.log('command response', res);
      notification.open({
        message: 'The command has been sent.',
      });
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onClose = () => {
    const params = {
      IMEI: feeder,
      command: 'force_close',
    };

    Api.SMARTFEEDER_COMMAND(params).then((res: any) => {
      notification.open({
        message: 'The command has been sent.',
      });
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const handleOpenCategories = (values) => {
    const newCloseCategories = [...closeCategories];
    for (let index = 0; index < values.length; index++) {
      const element = values[index];
      const i = newCloseCategories.indexOf(element);
      if (i !== -1) {
        newCloseCategories.splice(i, 1);
      }
    }
    setCloseCategories(newCloseCategories);
    setOpenCategories(values);
  };

  const handleCloseCategories = (values) => {
    const newOpenCategories = [...openCategories];
    for (let index = 0; index < values.length; index++) {
      const element = values[index];
      const i = newOpenCategories.indexOf(element);
      if (i !== -1) {
        newOpenCategories.splice(i, 1);
      }
    }
    setOpenCategories(newOpenCategories);
    setCloseCategories(values);
  };

  const handleSelectCameras = (value) => {
    setSelectedCameras(value);
  };

  const changeFeeder = (value) => {
    console.log(`selected ${value}`);
    setFeeder(value);
  };

  const onSave = () => {
    const params = {
      IMEI: feeder,
      default_mode: defaultMode,
      cameras: selectedCameras,
      open_categories: openCategories,
      close_categories: closeCategories,
      action_stay: actionStay,
      rain_close: closeInRain ? 1 : 0,
      dry_open: openInDry ? 1 : 0,
      open_sunrise: openSunrise,
      close_sunset: closeSunset,
      target_threshold: thresholdValue,
      nontarget_threshold: nonThresholdValue,
    };

    setLoading(true);
    Api.SMARTFEEDER_SETTING_POST(params).then(((res: any) => {
      setLoading(false);
    })).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  const onChangeTargetThreshold: InputNumberProps['onChange'] = (newValue) => {
    setThresholdValue(newValue as number);
  };

  const onChangeNonTargetThreshold: InputNumberProps['onChange'] = (newValue) => {
    setNonThresholdValue(newValue as number);
  };

  return (
    <>
      <Row>
        <Col md={10} xs={24}>
          <div className={classes.divSelectFeeder} style={{ padding: md ? 24 : 16 }}>
            <Space>
              <div className={classes.formInputLabel}>Smart Feeder:&nbsp;</div>
              <Select
                size="large"
                className={classes.select}
                onChange={changeFeeder}
                value={feeder}
              >
                {feeders.map((val: any, i) => (
                  <Select.Option key={val.id} value={val.IMEI}>{val.name}</Select.Option>
                ))}
              </Select>
              <Tag>
                {feeder}
              </Tag>
            </Space>
            <Card title="Manually Control" style={{ marginTop: '8px' }}>
              <div>
                <strong>Feeder State</strong>
                <div className={classes.subContent}>
                  <Space direction="vertical" size="middle">
                    <div className={classes.formInputLabel}>Door Position&nbsp;:&nbsp;<i>{feederState.door_position}</i></div>
                    <div className={classes.formInputLabel}>Rain Sensor&nbsp;:&nbsp;<i>{feederState.rain_sensor}</i></div>
                    <div className={classes.formInputLabel}>Food Level&nbsp;:&nbsp;<i>{feederState.feed_level}</i></div>
                    <div className={classes.formInputLabel}>CSQ&nbsp;:&nbsp;<i>{feederState.csq}</i></div>
                    <div className={classes.formInputLabel}>DBM&nbsp;:&nbsp;<i>{feederState.dbm}</i></div>
                  </Space>
                </div>
              </div>
              <div style={{ marginTop: '24px' }}>
                <strong>Feeder Action</strong>
                <div className={classes.subContent}>
                  <Space>
                    <div className={classes.divButton}>
                      <Button type="primary" onClick={onAllStop}>All Stop</Button>
                    </div>
                    <div className={classes.divButton}>
                      <Button type="primary" onClick={onOpen}>Open</Button>
                    </div>
                    <div className={classes.divButton}>
                      <Button type="primary" onClick={onClose}>Close</Button>
                    </div>
                  </Space>
                </div>
              </div>
            </Card>
          </div>
        </Col>
        <Col md={14} xs={24}>
          <Card title="Auto Open/Close" style={{ margin: md ? '24px 24px 0 0' : '16px' }}>
            <Spin spinning={loading} size="large">
              <Form
                form={form}
                style={{ marginTop: md ? 20 : 0 }}
                layout="vertical"
              >
                <div className={classes.profileItem}>
                  <div style={{ marginBottom: 24 }}>
                    <Space>
                      <div>Default Mode</div>
                      <Select style={{ width: '250px' }} value={defaultMode} onChange={(value) => setDefaultMode(value)}>
                        <Select.Option value="open">Open</Select.Option>
                        <Select.Option value="close">Close</Select.Option>
                      </Select>
                    </Space>
                  </div>
                  <Form.Item label="Cameras/Groups related with this feeder">
                    <Select
                      mode="multiple"
                      placeholder="Please select"
                      value={selectedCameras}
                      onChange={handleSelectCameras}
                    >
                      <Select.OptGroup label="Cameras">
                        {cameras.map((val: any) => (
                          <Select.Option key={val.id} value={val.id}>{val.name}</Select.Option>
                        ))}
                      </Select.OptGroup>
                      <Select.OptGroup label="Camera Groups">
                        {cameraGroups.map((val: any) => (
                          <Select.Option key={val.id} value={`group${val.id}`}>{val.name}</Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Categories to open">
                    <Select
                      mode="multiple"
                      placeholder="Please select"
                      value={openCategories}
                      onChange={handleOpenCategories}
                    >
                      <Select.OptGroup label="Cameras">
                        {categories.map((val: any) => (
                          <Select.Option key={val} value={val}>{val}</Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Categories to close">
                    <Select
                      mode="multiple"
                      placeholder="Please select"
                      value={closeCategories}
                      onChange={handleCloseCategories}
                    >
                      <Select.OptGroup label="Cameras">
                        {categories.map((val: any) => (
                          <Select.Option key={val} value={val}>{val}</Select.Option>
                        ))}
                      </Select.OptGroup>
                    </Select>
                  </Form.Item>
                  <Form.Item label="How long the feeder will stay open or closed after the action">
                    <Radio.Group onChange={(e) => setActionStay(e.target.value)} value={actionStay} optionType="button">
                      <Radio.Button value="5">5 minutes</Radio.Button>
                      <Radio.Button value="10">10 minutes</Radio.Button>
                      <Radio.Button value="30">30 minutes later</Radio.Button>
                      <Radio.Button value="60">1 hour later</Radio.Button>
                      <Radio.Button value="1000">never</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                  <div style={{ marginBottom: 24 }}>
                    <Space>
                      <div>Close in Rain</div>
                      <Checkbox checked={closeInRain} onChange={(e) => setCloseInRain(e.target.checked)} />
                      <div>Open in Dry</div>
                      <Checkbox checked={openInDry} onChange={(e) => setOpenInDry(e.target.checked)} />
                    </Space>
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Space>
                      <div>Sunrise Action</div>
                      <Select style={{ width: '250px' }} value={openSunrise} onChange={(value) => setOpenSunrise(value)}>
                        <Select.Option value="0">No Sunrise Action Required</Select.Option>
                        <Select.Option value="1">Open at Sunrise</Select.Option>
                        <Select.Option value="2">Open 15 minutes before Sunrise</Select.Option>
                        <Select.Option value="3">Open 30 minutes before Sunrise</Select.Option>
                      </Select>
                    </Space>
                  </div>
                  <div style={{ marginBottom: 24 }}>
                    <Space>
                      <div>Sunset Action&nbsp;</div>
                      <Select style={{ width: '250px' }} value={closeSunset} onChange={(value) => setCloseSunset(value)}>
                        <Select.Option value="0">No Sunset Action Required</Select.Option>
                        <Select.Option value="1">Close at Sunset</Select.Option>
                        <Select.Option value="2">Close 15 minutes after Sunset</Select.Option>
                        <Select.Option value="3">Close 30 minutes after Sunset</Select.Option>
                      </Select>
                    </Space>
                  </div>
                  <Form.Item label="Target Threshold">
                    <Row>
                      <Col span={12}>
                        <Slider
                          min={1}
                          max={10}
                          onChange={onChangeTargetThreshold}
                          value={typeof thresholdValue === 'number' ? thresholdValue : 1}
                        />
                      </Col>
                      <Col span={4}>
                        <InputNumber
                          min={1}
                          max={10}
                          style={{ margin: '0 16px' }}
                          value={thresholdValue}
                          onChange={onChangeTargetThreshold}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item label="Non Target Threshold">
                    <Row>
                      <Col span={12}>
                        <Slider
                          min={1}
                          max={10}
                          onChange={onChangeNonTargetThreshold}
                          value={typeof nonThresholdValue === 'number' ? nonThresholdValue : 1}
                        />
                      </Col>
                      <Col span={4}>
                        <InputNumber
                          min={1}
                          max={10}
                          style={{ margin: '0 16px' }}
                          value={nonThresholdValue}
                          onChange={onChangeNonTargetThreshold}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                </div>
              </Form>
            </Spin>
            <div className={classes.divButton}>
              <Button type="primary" onClick={onSave}>Save</Button>
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SmartFeeder;
